
const collapseToggleHTMLElement = document.querySelector('.js-toggle-collapse') as HTMLElement;
const mobileBreakpoint = 768
const windowWidth = window.innerWidth;

// Close event panels when you get to mobile breakpoint
const collapseEvents = () => {
    if (windowWidth <= mobileBreakpoint) {
        collapseToggleHTMLElement.classList.remove('collapsed');
        toggleCollapse();
    } else {
        collapseToggleHTMLElement.classList.add('collapsed');
        toggleCollapse();
    }
}


const toggleCollapse = () => {
    if (collapseToggleHTMLElement.classList.contains('collapsed')) {
        $('.collapse').collapse('show');
        collapseToggleHTMLElement.classList.remove('collapsed');
        collapseToggleHTMLElement.innerText = "Collapse all";
    } else {
        $('.collapse').collapse('hide');
        collapseToggleHTMLElement.classList.add('collapsed');
        collapseToggleHTMLElement.innerText = "Expand all";
    }
}

const initEvents = () => {
    collapseEvents();
}

// Events are now a global element so put a check in place to stop error when binding events
if (!!collapseToggleHTMLElement) {

    initEvents();

    collapseToggleHTMLElement.addEventListener('click', (event) => {
        event.preventDefault();
        toggleCollapse();
    })
}
